import React, { useState } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import ServiceNavigator from "../components/Services/ServiceNavigator";
import ServiceHeader from "../components/Services/ServiceHeader/ServiceHeader";
import ServiceFeatureList from "../components/Services/ServiceFeatureList/ServiceFeatureList";
import ServiceForm from "../components/Services/ServiceForm/ServiceForm";
import ServiceFormMock from "../components/Services/ServiceForm/ServiceFormMock";

import useFormatServices from "../components/Hooks/useFormatServices";
import Layout from "../components/Layout";

const Design = (props) => {
  const [selectedService, setSelectedService] = useState(null);
  const { t } = useTranslation();

  const data = {
    header: {
      pageTitle: t("TR_MENU_DESIGN"),
      subtitle: t("TR_DESIGN_SUBTITLE"),
    },
    color: "design",
    services: useFormatServices(props.data.designData.nodes),
    form: {
      title: t("TR_FORM_DESIGN_TITLE"),
      content: {
        topic: {
          id: "topic",
          label: t("TR_FORM_DESIGN_SUBTITLE"),
          type: "select",
        },
        name: {
          id: "name",
          label: t("TR_FORM_NAME"),
          required: true,
          type: "text",
        },
        company: {
          id: "company",
          label: t("TR_FORM_COMPANY"),
          required: false,
          type: "text",
        },
        email: {
          id: "email",
          label: t("TR_FORM_EMAIL"),
          required: true,
          type: "email",
        },

        message: {
          id: "message",
          label: t("TR_FORM_MESSAGE_SERVICE"),
        },
        privacy: {
          id: "privacy",
          label: {
            accept: t("TR_FORM_PRIVACY_ACCEPT"),
            privacy: t("TR_FORM_PRIVACY"),
          },
          required: true,
        },
        files: { id: "files", label: t("TR_FORM_FILES"), required: false },
        submit: { id: "send", label: t("TR_FORM_SEND") },
      },
    },
  };
  return (
    <div className='bg-neutral-50 text-neutral-600'>
      {selectedService && (
        <ServiceForm
          form={data.form}
          color={data.color}
          services={data.services}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
      )}
      <Layout>
        {data && (
          <>
            <ServiceHeader
              data={data}
              backgroundImg={
                props.data.backgroundImg.childImageSharp.gatsbyImageData
              }
            />
            <ServiceFeatureList
              data={data}
              setSelectedService={setSelectedService}
            />
            <ServiceNavigator section={data.color} />
          </>
        )}
        <ServiceFormMock />
      </Layout>
    </div>
  );
};

export default Design;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    designData: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/design/i" }
        frontmatter: { language: { eq: $language } }
      }
      sort: { order: ASC, fields: frontmatter___listOrder }
    ) {
      nodes {
        frontmatter {
          buttons {
            label
            link
            serviceName
          }
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          preTitle
          slug
          title
        }
        html
      }
    }
    backgroundImg: file(relativePath: { regex: "/designHeader/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export const Head = ({ data }) => {
  const title = JSON.parse(data.locales.edges[0].node.data).TR_MENU_DESIGN;

  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
